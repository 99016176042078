import { Icon } from '@coolfire/icons';
import type { LoaderFunctionArgs } from '@remix-run/node';
import { redirect } from '@remix-run/node';
import { Outlet } from '@remix-run/react';

export async function loader({ context }: LoaderFunctionArgs) {
	if (context.authInfo) throw redirect('/');

	return null;
}

export default function Auth() {
	return (
		<div className="flex h-full flex-col items-center justify-center gap-8">
			<Icon className="h-12" id="logo:coolfire-core" />
			<div className="flex w-full max-w-4xl flex-col items-center justify-center rounded-2xl border-2 border-gray-300 bg-white py-10">
				<Outlet />
				<p className="mt-20 text-gray-700">
					By using Core you agree to the{' '}
					<a
						href="https://www.coolfiresolutions.com/terms-of-service"
						target="_blank"
						rel="noopener noreferrer"
						className="text-blue-600 underline"
					>
						Terms of Service
					</a>{' '}
					and{' '}
					<a
						href="https://www.coolfiresolutions.com/privacy-policy"
						target="_blank"
						rel="noopener noreferrer"
						className="text-blue-600 underline"
					>
						Privacy Policy.
					</a>
				</p>
			</div>
		</div>
	);
}
